<template>
  <chat-window
      :current-user-id="currentUserId"
      :rooms="rooms"
      :rooms-loaded="roomsLoaded"
      :messages="messages"
      :messages-loaded="messagesLoaded"
      :selectedRoom="selectedRoom"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage"
  />
</template>

<script>
import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import {messagesRef} from "@/firestore";
import { parseTimestamp, isSameDay } from '@/utils/dates'


export default {
  components: {
    ChatWindow
  },
  data() {
    return {
      rooms: [
        {
          roomId: 1,
          roomName: 'Test User 1',
          avatar: null,
          users: [],
        }
      ],
      roomsLoaded: true,
      messagesLoaded: false,
      currentUserId: "1",

      messages: [
        // {
        //   _id: 1,
        //   sender_id: "1",
        //   content: "content",
        //   timestamp: new Date(),
        // },
        // {
        //   _id: 2,
        //   sender_id: "1",
        //   content: "content",
        //   timestamp: new Date(),
        // },
      ],
      messagesListeners: [],
      selectedRoom: null,
    }
  },
  methods: {
    sendMessage({ content, roomId, file, replyMessage }) {
      const doc = {
          content: content,
          timestamp: new Date(),
          sender_id: this.currentUserId,
      }
      messagesRef.add(doc)
      this.$log.debug("sendMessage", content, roomId, file, replyMessage)
    },
    fetchMessages({ room, options = {} }) {
      this.selectedRoom = room.roomId
      this.messagesListeners.forEach(value => value())
      const listen = messagesRef
          .orderBy('timestamp', 'asc')
          .onSnapshot(msgs => {
            const messages = []
            msgs.forEach(m => {
              const message = m.data()
              const date = new Date(message.timestamp.seconds * 1000)
              const timestampFormat = isSameDay(date, new Date()) ? 'HH:mm' : 'DD/MM/YY'
              let timestamp = parseTimestamp(message.timestamp, timestampFormat)
              if (timestampFormat === 'HH:mm') timestamp = `Today, ${timestamp}`
              message.timestamp = timestamp
              message._id = m.id
              messages.push(message)
              console.log("ПРИШЛО СООБЩЕНИЕ")
              document.querySelector('.new-messsages-count').classList.add('new-alert');
            })
            this.messages = messages
            this.messagesLoaded = true
          }
      )
      this.messagesListeners.push(listen)
      this.$log.debug("fetchMessages", room, options)
    }
  }
}
</script>
